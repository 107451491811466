import React, { useState, useRef, useEffect } from 'react';
import './App.css';

function App() {
  const [userInput, setUserInput] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const chatBoxRef = useRef(null);

  let backend_url = process.env.REACT_APP_BACKEND_URL || 'http://127.0.0.1:5001'
  const handleSend = async () => {
    if (!userInput) return;

    const newChatHistory = [...chatHistory, { sender: 'You', message: userInput }];
    setChatHistory(newChatHistory);
    setUserInput("");
    setLoading(true);

    try {
      const res = await fetch(backend_url+'/chat', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          input: userInput,
          chat_history: newChatHistory.map((chat) => ({
            content: chat.message,
            role: chat.sender === 'You' ? 'human' : 'ai',
          })),
        }),
      });

      const data = await res.json();

      setChatHistory((prev) => [
        ...prev,
        { sender: 'AI', message: data.response },
      ]);
    } catch (error) {
      console.error('Error communicating with the Flask app:', error);
      setChatHistory((prev) => [
        ...prev,
        { sender: 'AI', message: 'An error occurred. Please try again.' },
      ]);
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [chatHistory, loading]);

  const createMarkup = (message) => {
    return { __html: message };
  };

  return (
    <div className="App">
      <div className="chat-container">
        <div className="chat-header">
          <h1>HGI Support</h1>
        </div>
        <div className="chat-box" ref={chatBoxRef}>
          {chatHistory.map((chat, index) => (
            <div
              key={index}
              className={chat.sender === 'You' ? 'chat-you' : 'chat-ai'}
            >
              <div
                className="chat-bubble"
                dangerouslySetInnerHTML={createMarkup(chat.message)}
              />
            </div>
          ))}
          {loading && (
            <div className="chat-ai">
              <div className="chat-bubble">We are working on your query. Please wait...</div>
            </div>
          )}
        </div>
        <div className="input-box">
          <textarea
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            onKeyDown={handleKeyPress}
            placeholder="Type your message..."
            rows={1}
            className="text-area"
          />
          <button onClick={handleSend} disabled={loading}>Send</button>
        </div>
      </div>
    </div>
  );
}

export default App;